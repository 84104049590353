<template>
  <div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-card>
      <b-form-group
        label="Fournisseur"
      >
        <validation-provider
          #default="{ errors }"
          name="Fournisseur"
        >
          <v-select
            v-model="supplier"
            :clearable="false"
            placeholder="Fournisseur"
            label="username"
            :options="suppliers"
            @input="getOrders"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Référence de colis"
        label-for="vi-first-name"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="MaximizeIcon" />
          </b-input-group-prepend>
          <b-form-input
            ref="select"
            v-model="ref"
            placeholder="Référence de colis"
            @change="checkOrder"
          />
        </b-input-group>
      </b-form-group>
    </b-card>

    <!-- table -->
    <div class="d-flex justify-content-between">
      <b-card>
        <h4>retour au depot : {{ orders.length }}</h4>
        <vue-good-table
          :columns="columns"
          :rows="processedOrders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="CheckSquareIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  color="green"
                  @click="addItem(props.row)"
                />
                <b-tooltip
                  title="Ajouter"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card class="ml-1">
        <h4>retour au fournisseur : {{ selected.length }}</h4>
        <vue-good-table
          :columns="columns"
          :rows="selected"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="DeleteIcon"
                  color="red"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="resetItem(props.row)"
                />
                <b-tooltip
                  title="Annuler"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>

        </vue-good-table>

        <b-form-group class="d-flex justify-content-end">
          <b-button
            variant="gradient-primary"
            class="ml-1 d-inline-block mt-1"
            :class="{ 'disabled-cursor': isLoading || !isReturnVoucherValid }"
            :disabled="isLoading || !isReturnVoucherValid"
            @click="addReturnVoucher"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Enregistrer</span>
          </b-button>
        </b-form-group>
      </b-card>

    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ok from '@/assets/audio/ok.mp3'
import notexist from '@/assets/audio/notexist.mp3'
import isAnExchange from '@/assets/audio/exchange.mp3'

export default {
  components: {
    BButton,
    ValidationProvider,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BTooltip,
    vSelect,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      suppliers: [],
      supplier: '',
      date: '01-10-2021',
      status: '',
      pageLength: 10,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'BL',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'BL',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Status',
          },
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Client',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Gouvernorat',
          },
        },
      ],
      orders: [],
      selected: [],
      searchTerm: '',
      ref: '',
      user: {},
      isLoading: false,
      ok,
      notexist,
      isAnExchange,
    }
  },
  computed: {
    isReturnVoucherValid() {
      return (
        this.supplier.id !== ''
        && this.selected.length > 0
      )
    },
    processedOrders() {
      return this.orders.map(order => {
        const processedOrder = { ...order }

        if (order.status === 2) {
          processedOrder.status = 'colis au depot'
        } else if (order.status === 5) {
          processedOrder.status = 'Retour au fournisseur'
        }

        return processedOrder
      })
    },
  },
  created() {
    this.user = storeAuth.state.user
    this.getSuppliers()
  },
  mounted() {
    this.$refs.select.focus()
  },
  methods: {
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    checkOrder() {
      const result = this.orders.find(element => element.id === (this.ref))
      if (result) {
        if (result.is_an_exchange) {
          this.playAudio(isAnExchange)
        }
        this.addItem(result).then(() => { this.ref = '' })
        this.playAudio(ok)
      } else {
        this.withTitle('Colis non exist')
        this.playAudio(notexist)
      }
    },
    async getOrders() {
      if (this.supplier !== '') {
        this.isLoading = true
        const { data } = await axios.get(`api/orders/get-orders-allowed-to-be-returned-definitely/${this.supplier.id}`)
        this.orders = data
        this.selected = []
        this.isLoading = false
      }
    },
    async addReturnVoucher() {
      const obj = {
        supplier: this.supplier.id,
        orders: this.selected,
        repository: this.user.repository,
        user: this.user,
      }
      try {
        this.isLoading = true
        const response = await axios.post('/api/return-vouchers/create-return-voucher/', obj)
        this.showToast('success', 'top-center', 'Bon de retour ajouté avec succés')
        this.$router.push(`/bons-retours/show/${response.data}`)
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    async getSuppliers() {
      this.isLoading = true
      const { data } = await axios.get('api/auth/users/', {
        params: {
          role: 'supplier',
          repository: storeAuth.state.user.repository,
        },
      })
      this.suppliers = data
      this.isLoading = false
    },
    async addItem(item) {
      this.selected.push(item)
      const i = this.orders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      this.orders.splice(i, 1)
    },
    async resetItem(item) {
      this.orders.push(item)
      const i = this.selected.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      this.selected.splice(i, 1)
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
